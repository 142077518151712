<template>
    <div class="row" v-if="data.prix_korea && data.prix_korea.prix_korea_prices">
        <div class="col-12 col-lg-12 wrapper-box-fiche"> 
            <div class="box-white-fiche">
                <table class="comparateur2 text-center">
                    <thead>
                        <tr>
                            <th class="gray">Public Price (VAT included) (KRW)</th>
                            <th class="gray">Public Price (VAT excluded) (KRW)</th>
                            <th class="gray">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{data.prix_korea.prix_korea_prices.price_included}}</td>
                            <td class="text-center">{{data.prix_korea.prix_korea_prices.price_excluded}}</td>
                            <td class="text-center">{{data.prix_korea.prix_korea_prices.price_date}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>             
</template>


<script>
export default {
  name: "prix_korea",
  components: {},
  methods: {},
  mounted() {
    if (this.$i18n.locale == "de") {
      this.lang = "de";
    }
  },
  updated() {},
  computed: {
      data() {
            return this.$store.getters['detail/data']
        }
  },
  unmounted() {},
};
</script>